import React from 'react'
import { useState, useRef, useEffect } from 'react'

import '../assets/scss/section_hero-product.scss'

const SectionHeroProduct = ({ data }) => {
    data = data.section__hero_product

    const [slideContent, setSlideContent] = useState(0);
    const slideFade = useRef();
    const borderGrow = useRef();

    useEffect(() => {
        slideFade.current.animate({ opacity: [0, 1] }, 500)
        borderGrow.current.animate({ width: [0, 1] }, 500)
    }, [slideContent]);


    return (
        <div className='hero-product container mx-auto flex flex-row justify-between items-center'>
            <div className="col-intro flex flex-col">
                <h1 className='title' dangerouslySetInnerHTML={{ __html: data.content.title }}></h1>
                <p className="intro">{data.content.text}</p>

                <div className="gallery-nav flex flex-col overflow-auto origin-left scale-110 md:scale-100">
                    {data.gallery.map((item, index) =>
                        <span className={(slideContent == index) && 'nav-item flex active' || 'nav-item flex'} key={index} onClick={() => setSlideContent(index)}>
                            {item.image_title}
                            <div className="nav-item-space"></div>
                            <>
                                {slideContent === index &&
                                    <div className="active-border" ref={borderGrow}></div>
                                }
                            </>
                        </span>
                    )}
                </div>
            </div>
            <div className="gallery">
                {data.gallery.map((item, index) =>
                    <>
                        {slideContent === index &&
                            <img src={item.image.url} alt={item.image.alt} key={index} ref={slideFade} />
                        }
                    </>
                )}
            </div>
        </div>
    )
}

export default SectionHeroProduct