import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/section__list-image.scss'

const SectionListImage = ({data}) => {
    // console.log(data)
    return (
        <section className='list-image'>
            <div className="list-image__wrap container mx-auto flex items-center">
                <div className="info">
                    {data.text &&
                        <div className="text" dangerouslySetInnerHTML={{__html: data.text}} />
                    }
                    {data.list &&
                        <div className="list flex flex-wrap">
                            {data.list.map((item, index) =>
                                <Link to={item?.link?.url} className="list__item" key={index}>
                                    {item?.link?.title}
                                </Link>
                            )}
                        </div>
                    }
                </div>
                {data.image &&
                    <img className='image' src={data.image.url} alt="" />
                }
            </div>
        </section>
    )
}

export default SectionListImage