import React from 'react';
import { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';

import '../assets/scss/navbar.scss';
import menuOpen from '../assets/images/menu-open.svg';
import menuClose from '../assets/images/menu-close.svg';


const Navbar = ({ data }) => {
    const location = useLocation();
    const headerAcf = data.header;
    const footerAcf = data.footer;

    let locationPathname = useLocation().pathname;

    function openMega() {
        document.querySelector('#megaNav').classList.add('active');
    }

    function closeMega() {
        document.querySelector('#megaNav').classList.remove('active');
    }

    useEffect(() => {
        const navbar = document.querySelector('#navbar');
        if (navbar) {
            document.querySelector('#navbar').animate([
                { opacity: '0' },
                { opacity: '1' }
            ], {
                duration: 1200,
            });
        }
    }, [location]);

    return (
        <>
            <div id='navbar'>
                <div className='container mx-auto flex flex-row justify-between items-center'>

                    {headerAcf?.logo &&
                        <div className="logo">

                            <Link to='/'>
                                <img src={headerAcf.logo.url} alt="" />
                            </Link>
                        </div>
                    }

                    <div className="navbar-right flex flex-row items-center">

                        <nav id='nav-links-container' className='flex flex-row items-center'>
                            {headerAcf?.main_nav.map((navItem, index) =>
                                <Link
                                    to={navItem.page.url}
                                    className='nav-links'
                                    key={index}>
                                    {navItem.page.title}
                                </Link>
                            )}
                        </nav>
                        <div id="megaToggle" className="mega-menu-toggle" onClick={openMega}>
                            <img src={menuOpen} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='nav-spacer'></div>

            <div className="mega-nav flex flex-col justify-between" id='megaNav'>
                <div className="mega-nav-header container mx-auto flex flex-row justify-between">
                    {headerAcf?.logo &&
                        <div className="logo">

                            <Link to='/' onClick={closeMega}>
                                <img src={headerAcf.logo.url} alt="" />
                            </Link>
                        </div>
                    }

                    <div id="megaClose" className="mega-menu-toggle flex flex-col justify-center" onClick={closeMega}>
                        <img src={menuClose} alt="" />
                    </div>
                </div>
                <div className="mega-menu-content container mx-auto flex flex-row">
                    <div className="col flex flex-col col-big">
                        {footerAcf?.sitemap_big.links.map((item, index) =>
                            <Link onClick={closeMega} to={item.link.url} key={index} className={locationPathname === item.link.url ? 'active' : ''}>
                                <div className="active-border"></div>
                                {item.link.title}</Link>
                        )}
                    </div>
                    <div className="col flex flex-col col-small">
                        {footerAcf?.sitemap_small.links.map((item, index) =>
                            <Link onClick={closeMega} to={item.link.url} key={index}>{item.link.title}</Link>
                        )}
                    </div>
                </div>
                <div className="mega-menu-foot container mx-auto flex flex-row justify-between items-center">
                    <div className="social-row flex flex-row">
                        {footerAcf?.socials.links.map((item, index) =>
                            <a href={item.link} key={index}>
                                <img src={item.icon.url} alt={item.icon.alt} />
                            </a>
                        )}
                    </div>
                    <div className="mail">
                        <a href={`mailto: ${footerAcf?.socials.email}`}>{footerAcf.socials.email}</a>
                    </div>
                    <div className="phone">
                        <a href={`mailto: ${footerAcf?.socials.telefoonnr}`}>{footerAcf.socials.telefoonnr}</a>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Navbar