import { useState } from "react"
import axios from "axios"
import Datepicker, { registerLocale, setDefaultLocale }  from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { data } from "autoprefixer";
import nl from 'date-fns/locale/nl';
import InputField from "./components/fields/InputField";
registerLocale('nl', nl);
setDefaultLocale('nl');


const apilink = `https://admin.easyhaarden.nl/wp-json/gf/v2/forms/2/submissions`;

const validationLink = `https://admin.easyhaarden.nl/wp-json/gf/v2/forms/2/submissions/validation`;

const ContactForm = () => {
    require('form-data');
    
    const [error, setError] = useState([]);
    const [date, setDate] = useState(null)
    const [validationErrors, setValidationErrors] = useState([]);
    const [isValid, setIsValid] = useState(false);

    const [input13, setInput13] = useState("");
    const [input14, setInput14] = useState("");
    const [input17, setInput17] = useState("");
    const [input18, setInput18] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    async function submitGravityForm() { 
        let formData = new FormData();
        setIsValid(false);
        setIsLoading(true);
        try {
            if(date != null) {
                let formattedDate = date.toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric'});
                formData.set('input_10', formattedDate);
            }

            // Only set the value if it's not empty
            if(input13 !== "") formData.append('input_13', input13);
            if(input14 !== "") formData.append('input_14', input14);
            if(input17 !== "") formData.append('input_17', input17);
            if(input18 !== "") formData.append('input_18', input18);


            // loading state business logic



            const validationResponse = await fetch(validationLink, {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(data => {
                    if(Object.keys(data.validation_messages).length > 0) {
                        setValidationErrors(data.validation_messages);
                        setIsLoading(false);
                        setIsValid(false);
                    } else {
                        setValidationErrors([]);
                        setIsValid(true);
                    }
                    return data;
                });
                
            if(validationResponse.is_valid) {
                const submitResponse = await fetch(apilink, { 
                        method: "POST", 
                        body: formData,
                    })
                        .then((response) => { 
                            setInput13("");
                            setInput14("");
                            setInput17("");
                            setInput18("");
                            return response.json() 
                        })
                        .then((data) => {
                            setIsLoading(false);
                            console.log("Form submitted successfully:", data)
                        }); 
            }
        } catch (error) {
            // console.log("Error submitting form:", error)
            setIsLoading(false);
        }
    }


    const handleSubmit = async (event) => { 
        event.preventDefault() 
        submitGravityForm() 
    }

    return (
        <>      
            {/* success message */}
            {validationErrors.length === 0 && isValid &&
                <div className="w-full max-w-2xl mx-auto mt-12">
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Success!</strong>
                    <span className="block sm:inline"> De informatie is succesvol verzonden.</span>
                </div>
            </div>}

            {/* React loading state when sending the form */}
            {isLoading && <div className="w-full max-w-2xl mx-auto mt-12">
                <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Laden...</strong>
                    <span className="block sm:inline"> De informatie wordt verzonden.</span>
                </div>
            </div>}
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="grid gap-6 grid-cols-2 mt-12">
                {input13 !== undefined && (
                    <InputField
                        name="input_13"
                        placeholder="Voornaam*"
                        value={input13 ?? ''}
                        error={validationErrors[13] ?? null}
                        onChangeHandler={(e) => setInput13(e.target.value)}
                    />
                )}
                {input14 !== undefined && (
                    <InputField
                        name="input_14"
                        placeholder="Achternaam*"
                        value={input14 ?? ''}
                        error={validationErrors[14] ?? null}
                        onChangeHandler={(e) => setInput14(e.target.value)}
                    />
                )}
                {input18 !== undefined && (
                    <InputField
                        type="email"
                        name="input_18"
                        placeholder="E-mailadres*"
                        required
                        value={input18 ?? ''}
                        error={validationErrors[18] ?? null}
                        onChangeHandler={(e) => setInput18(e.target.value)}
                    />
                )}
                {input17 !== undefined && (
                    <InputField
                        textarea
                        name="input_17"
                        placeholder="Bericht"
                        value={input17 ?? ''}
                        error={validationErrors[17] ?? null}
                        onChangeHandler={(e) => setInput17(e.target.value)}
                    />
                )}

                <div className="col-span-2 flex justify-start">
                    <button className="bg-black text-white py-2 px-4 w-[30%]">Verzenden</button>
                </div>
            </form>
        </>  
    )
}

export default ContactForm
