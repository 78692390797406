import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import '../assets/scss/section__text_slider.scss';

const SectionTextSlider = ({ data }) => {
    const [slideContent, setSlideContent] = useState(0);

    const dataAcf = data['section__text_slider'];
    const slideFade = useRef();

    useEffect(() => {
        if(slideFade.current) {
            slideFade.current.animate({ opacity: [0, 1] }, 500)
        }
    }, [slideContent]);

    return (
        <section className='text_slider'>
            <div className='container mx-auto'>
                {dataAcf.centered_title &&
                    <h2>{dataAcf.centered_title}</h2>
                }
                {dataAcf.slider &&
                    <div className="flex flex-row items-start">
                        <div className="col col-nav flex flex-col">
                            {dataAcf.slider.map((navItem, index) =>
                                <li className={(slideContent == index) && 'nav-item active' || 'nav-item'}
                                    key={index}
                                    onClick={() => setSlideContent(index)}>
                                    {navItem.slider_title}
                                </li>
                            )}
                        </div>
                        <div className="col col-content">
                            {dataAcf.slider.map((contentItem, index) =>
                                <div key={index}>
                                    {slideContent == index &&
                                        <div className="slider-content flex flex-col" key={index} ref={slideFade}>
                                            <div className='content' dangerouslySetInnerHTML={{ __html: contentItem.slider_content.slider_text }}></div>
                                            {contentItem.slider_content?.link &&
                                                <Link to={contentItem.slider_content.link.url}>
                                                    {contentItem.slider_content.link.title}
                                                </Link>
                                            }
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default SectionTextSlider