import { useState } from "react"
import axios from "axios"
import Datepicker, { registerLocale, setDefaultLocale }  from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { data } from "autoprefixer";
import nl from 'date-fns/locale/nl';
import InputField from "./components/fields/InputField";
registerLocale('nl', nl);
setDefaultLocale('nl');


const apilink = `https://admin.easyhaarden.nl/wp-json/gf/v2/forms/1/submissions`;

const validationLink = `https://admin.easyhaarden.nl/wp-json/gf/v2/forms/1/submissions/validation`;

const WarrantyForm = () => {
    require('form-data');
    
    const [error, setError] = useState([]);
    const [date, setDate] = useState(null)
    const [validationErrors, setValidationErrors] = useState([]);
    const [isValid, setIsValid] = useState(false);

    const [input13, setInput13] = useState("");
    const [input14, setInput14] = useState("");
    const [input4, setInput4] = useState("");
    const [input5, setInput5] = useState("");
    const [input6, setInput6] = useState("");
    const [input7, setInput7] = useState("");
    const [input8, setInput8] = useState("");
    const [input9, setInput9] = useState("");
    const [input10, setInput10] = useState("");
    const [input11, setInput11] = useState("");
    const [input12, setInput12] = useState(false);
    const [input16, setInput16] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    async function submitGravityForm() { 
        let formData = new FormData();
        setIsValid(false);
        setIsLoading(true);
        try {
            if(date != null) {
                let formattedDate = date.toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric'});
                formData.set('input_10', formattedDate);
            }

            // Only set the value if it's not empty
            if(input13 !== "") formData.append('input_13', input13);
            if(input14 !== "") formData.append('input_14', input14);
            if(input4 !== "") formData.append('input_4', input4);
            if(input5 !== "") formData.append('input_5', input5);
            if(input6 !== "") formData.append('input_6', input6);
            if(input7 !== "") formData.append('input_7', input7);
            if(input8 !== "") formData.append('input_8', input8);
            if(input9 !== "") formData.append('input_9', input9);
            if(input11 !== "") formData.append('input_11', input11);
            if(input12 !== false) formData.append('input_12', input12);
            if(input16 !== null) formData.append('input_16', input16);

            console.log(formData.get('input_12'));

            // loading state business logic



            const validationResponse = await fetch(validationLink, {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(data => {
                    if(Object.keys(data.validation_messages).length > 0) {
                        setValidationErrors(data.validation_messages);
                        setIsLoading(false);
                        setIsValid(false);
                    } else {
                        setValidationErrors([]);
                        setIsValid(true);
                    }
                    return data;
                });
                
            if(validationResponse.is_valid) {
                const submitResponse = await fetch(apilink, { 
                        method: "POST", 
                        body: formData,
                    })
                        .then((response) => { 
                            setInput13("");
                            setInput14("");
                            setInput4("");
                            setInput5("");
                            setInput6("");
                            setInput7("");
                            setInput8("");
                            setInput9("");
                            setDate("");
                            setInput11("");
                            setInput12(false);
                            setInput16(null);
                            return response.json() 
                        })
                        .then((data) => {
                            setIsLoading(false);
                            console.log("Form submitted successfully:", data)
                        }); 
            }
        } catch (error) {
            // console.log("Error submitting form:", error)
            setIsLoading(false);
        }
    }

    const handleFileUploadChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png'];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            // if the error variable contains an error for the file input, remove it
            if (error.some((err) => err.input_16)) {
                // remove the error for input_16
                setError(error.filter((err) => !err.hasOwnProperty('input_16')));
            }

            setInput16(selectedFile);
        } else {
            setInput16(null);
            if (!error.some((err) => err.input_16)) {
                setError([...error, {input_16: 'Invalid file type. Only PDF and PNG are allowed.'}]);
            }
        }
      };

    const handleDateSelect = (date) => {
        setDate(date);
    }

    const handleDateChange = (date) => {
        setDate(date);
    }

    const handleSubmit = async (event) => { 
        event.preventDefault() 
        submitGravityForm() 
    }

    return (
        <>      
            {/* success message */}
            {validationErrors.length === 0 && isValid &&
                <div className="w-full max-w-2xl mx-auto mt-12">
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Success!</strong>
                    <span className="block sm:inline"> De informatie is succesvol verzonden.</span>
                </div>
            </div>}

            {/* React loading state when sending the form */}
            {isLoading && <div className="w-full max-w-2xl mx-auto mt-12">
                <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Laden...</strong>
                    <span className="block sm:inline"> De informatie wordt verzonden.</span>
                </div>
            </div>}
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="grid gap-6 grid-cols-2 mt-12">
                {input13 !== undefined && (
                    <InputField
                        name="input_13"
                        placeholder="Voornaam*"
                        value={input13 ?? ''}
                        error={validationErrors[13] ?? null}
                        onChangeHandler={(e) => setInput13(e.target.value)}
                    />
                )}
                {input14 !== undefined && (
                    <InputField
                        name="input_14"
                        placeholder="Achternaam*"
                        value={input14 ?? ''}
                        error={validationErrors[14] ?? null}
                        onChangeHandler={(e) => setInput14(e.target.value)}
                    />
                )}
                {input4 !== undefined && (
                <InputField
                    name="input_4"
                    placeholder="Adres"
                    value={input4 ?? ''}
                    error={validationErrors[4] ?? null}
                    onChangeHandler={(e) => setInput4(e.target.value)}
                />
                )}
                {input5 !== undefined && (
                <InputField
                    name="input_5"
                    placeholder="Postcode"
                    value={input5 ?? ''}
                    error={validationErrors[5] ?? null}
                    onChangeHandler={(e) => setInput5(e.target.value)}
                />
                )}
                {input6 !== undefined && (
                <InputField
                    name="input_6"
                    placeholder="Plaats"
                    value={input6 ?? ''}
                    error={validationErrors[6] ?? null}
                    onChangeHandler={(e) => setInput6(e.target.value)}
                />
                )}
                {input7 !== undefined && (
                <InputField
                    name="input_7"
                    placeholder="Email*"
                    value={input7 ?? ''}
                    error={validationErrors[7] ?? null}
                    onChangeHandler={(e) => setInput7(e.target.value)}
                />
                )}
                {input8 !== undefined && (
                <InputField
                    name="input_8"
                    placeholder="Productnaam*"
                    value={input8 ?? ''}
                    error={validationErrors[8] ?? null}
                    onChangeHandler={(e) => setInput8(e.target.value)}
                />
                )}
                {input9 !== undefined && (
                <InputField
                    name="input_9"
                    placeholder="Serienummer*"
                    value={input9 ?? ''}
                    error={validationErrors[9] ?? null}
                    onChangeHandler={(e) => setInput9(e.target.value)}
                />
                )}
                <Datepicker
                    locale={nl}
                    selected={date}
                    dateFormat="dd/MM/yyyy"
                    className="border-b-2 border-t-0 border-x-0 border-gray-500 focus:ring-0 focus:border-b-2 focus:border-gray-500 focus:outline-0 bg-transparent py-2"
                    onSelect={handleDateSelect} //when day is clicked
                    onChange={handleDateChange} //only when value has changed
                    placeholderText="Datum aankoop*"
                />
                {input11 !== undefined && (
                <InputField
                    name="input_11"
                    placeholder="Retailer*"
                    value={input11 ?? ''}
                    error={validationErrors[11] ?? null}
                    onChangeHandler={(e) => setInput11(e.target.value)}
                />
                )}
                {input16 !== undefined && (
                <div className="col-span-2 flex gap-6 my-6">
                    <label htmlFor="input_16">Factuur</label>
                    <input
                        type="file"
                        name="input_16"
                        accept="application/pdf,image/png"
                        placeholder="Upload foto"
                        onChange={handleFileUploadChange}
                    />
                    <div>
                       {validationErrors[16] &&  <p className="text-red-500 text-sm">
                            {validationErrors[16] || null}
                        </p>}
                    </div>

                </div>
                )}
                {input12 !== undefined && (
                    <div className="col-span-2 flex gap-2 my-6">
                        <input
                            type="checkbox"
                            name="input_12"
                            id="input_12"
                            value={input12 ?? ''}
                            required
                            onChange={(e) => setInput12(e.target.checked)}
                            checked={input12}
                        />
                        <label htmlFor="input_12">Ik ga akkoord met de <a href="https://easyhaarden.nl/algemene-voorwaarden" target="_blank">algemene voorwaarden</a></label>
                    </div>
                )}
                <div className="col-span-2 flex justify-start">
                    <button className="bg-black text-white py-2 px-4 w-[30%]">Verzenden</button>
                </div>
            </form>
        </>  
    )
}

export default WarrantyForm
