import React from 'react';
import { Link } from 'react-router-dom'
import { FetchApiData } from '../functions/helpers';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import '../assets/scss/section__stories_slider.scss';

const SectionStoriesSlider = ({ data }) => {
    // Get 5 stories
    let stories = FetchApiData('wp-json/wp/v2/stories/?per_page=5');
    data = data.section__stories_slider
    // console.log(stories)

    const FeatureItem = ({ items }) => {
        return (
            <Swiper
                spaceBetween={20}
                slidesPerView={1.3}
                breakpoints={{
                    640: {
                        slidesPerView: 2.2,
                    },
                    890: {
                        slidesPerView: 2.8,
                    },
                }}
            >
                {items && items.map((item, index) =>
                    <SwiperSlide key={index}>
                        <Link to={'/stories/' + item.slug}>
                            <div className="featurd-image-content">
                                <img src={item.yoast_head_json.og_image[0].url} />
                                <h3>{item.slug.replaceAll('-', ' ')}</h3>
                            </div>
                        </Link>
                    </SwiperSlide>
                )}
            </Swiper>
        );
    }

    return (
        <section className='featured-image container mx-auto'>
            <div className='featured-image-header'>
                <div className='header-main'>
                    <h2>{data.stories_slider_title}</h2>
                    <div className="line"></div>
                </div>
                <Link to={data.stories_slider_button.url}>{data.stories_slider_button.title}</Link>
            </div>
            <div className="featurd-image-container">
                <FeatureItem items={stories} />
            </div>
        </section>
    );
}

export default SectionStoriesSlider