import React from 'react';
import '../../assets/scss/FilterCheckbox.scss';

const FilterCheckbox = ({ filterState, filterItems, radio }) => {
	const handleClickCheckbox = (item) => {
		if (filterState.filter.includes(item)) {
			radio ||
				filterState.setFilter(
					filterState.filter.filter((i) => i !== item)
				);
		} else {
			filterState.setFilter(
				radio ? [item] : [...filterState.filter, item]
			);
		}
	};


	const filterItemsSortOrder = [
		'Alles',
		'New Forest Insert',
		'New Forest Square',
		'New Forest 870',
		'New Forest 1200',
		'New Forest 1600',
		'New Forest 1900',
		'New Forest 2400',
		'Brockenhurst',
		'Winchester',
		'Holbury',
		'Ashurst',
		'Bramshaw',
		'Hinton',
	];

	const filterItemsSorted = filterItems.sort((a, b) => {
		return (
			filterItemsSortOrder.indexOf(a) -
			filterItemsSortOrder.indexOf(b)
		);
		// filter empty strings
	}).filter(item => item !== '');
		

	return (
		<section className="filter w-full">
			{filterItemsSorted.map((item, index) => (
				<div className="filter__item flex items-center" key={index}>
					<input
						type="checkbox"
						id={item}
						onChange={() => handleClickCheckbox(item)}
						checked={filterState.filter.includes(item)}
					/>
					<label className="hover:cursor-pointer" htmlFor={item}>
						{item}
					</label>
				</div>
			))}
		</section>
	);
};

export default FilterCheckbox;
