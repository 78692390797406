import React, { useEffect, useRef, useState } from 'react';
import upArrow from '../assets/images/up-arrow.svg';
import '../assets/scss/section_faq_items.scss';

const SectionFaqItems = ({ data }) => {
	const faqItems = data.section__faq_items;
	const dropdownFade = useRef();

	const [dropdown, setDropdown] = useState([]);

	const handleClickDropdown = (index) => {
		if (dropdown.includes(index)) {
			setDropdown(dropdown.filter((val) => val !== index));
		} else {
			setDropdown([...dropdown, index]);
		}
	};

	const Questions = ({ items }) => {
		// console.log(items);
		return (
			<section className="faq__items">
				{items &&
					items.map((item, index) => (
						<div
							key={index}
							className={
								'pb-4 mb-2 mt-4 border-b-2 bolder-black ' +
								(dropdown.includes(index)
									? 'faq-question active'
									: 'faq-question')
							}
						>
							<div className="faq-question-container">
								<div
									className="faq-question-title items-center"
									onClick={() => handleClickDropdown(index)}
								>
									<h3>{item?.faq_items_title}</h3>
									<img
										src={upArrow}
										alt="arrow key"
										className="faq-question-image"
									/>
								</div>

								{dropdown.includes(index) && (
									<div
										className="faq-question-content"
										ref={dropdownFade}
										dangerouslySetInnerHTML={{
											__html: item?.faq_items_answer,
										}}
									></div>
								)}
							</div>
						</div>
					))}
			</section>
		);
	};

	return (
		<div className="fac-container container mx-auto">
			{/* SectionFaqItems */}
			<Questions items={faqItems} />
		</div>
	);
};

export default SectionFaqItems;
