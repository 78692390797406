import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//import { Helmet } from "react-helmet"

import { FetchApiData, getCategoryNamesAndIds } from '../functions/helpers'

import Transitions from '../partials/animations/Transitions'


import '../assets/scss/stories.scss';
import FilterCheckbox from '../partials/components/FilterCheckbox'
import {gsap} from 'gsap';

const Stories = ({ data }) => {
    const [filter, setFilter] = useState(['Alles'])
    const [filteredDataState, setFilteredDataState] = useState(null)
    const isEven = num => num % 2 !== 0;
    let currentSlug = data;
    currentSlug = currentSlug.replace(/\//g, "");

    let stories = FetchApiData('wp-json/wp/v2/' + currentSlug);
    const optionsAcf = FetchApiData('wp-json/acf/v3/options/stories');
    let categories = FetchApiData('/wp-json/wp/v2/categories');

    const allCategoryNamesAndIds = getCategoryNamesAndIds(stories, categories)
    const allCategoryNames = allCategoryNamesAndIds.map(i => i.name);

    const filteredIDs = allCategoryNamesAndIds.filter(i => filter.includes(i.name)).map(i => i.id);

    const filteredData = () => {
        let prevData = document.querySelectorAll('.stories-single');
        
        if (stories) {
            if (filter.includes('Alles')) {
                animateData(prevData, stories)
                return
            }
            let filteredData = []
            stories.map(post => {
                post.categories.map(cat => {
                    if (filteredIDs.includes(cat) && !filteredData.includes(post)) {
                        filteredData.push(post)
                    }
                })
            })
            animateData(prevData, filteredData)
            return 
        } else {
            setFilteredDataState(null)
            return
        }
    }

    const animateData = (prevData, newData) => {

        gsap.to(prevData, {
            duration: 0.5,
            opacity: 0,
            y: -10,
            ease: 'power3.inOut',
            stagger: 0.1,
            onComplete: () => {
                setFilteredDataState(newData)
                return
            }
        })
    }

    useEffect(() => {
        filteredData()
    }, [filter, stories])

    useEffect(() => {
        let newDataElements = document.querySelectorAll('.stories-single');

        gsap.fromTo(newDataElements, 
            {
                y: -10,
                opacity: 0
            },{
                duration: 0.5,
                opacity: 1,
                y: 0,
                ease: 'power3.inOut',
                stagger: 0.1,
            })
    }, [filteredDataState])

    return (
        <Transitions>
            <div className='page-container'>
                {/* <Helmet>
                    <title>{stories[0].yoast_head_json.title}</title>
                    <meta property='og:locale' content='nl_NL' />
                    <meta name="description" content={stories[0].yoast_head_json.description} />
                    <meta property='og:title' content={stories[0].yoast_head_json.og_title} />
                    <meta property='og:description' content={stories[0].yoast_head_json.og_description} />
                    <meta property='og:url' content={stories[0].yoast_head_json.og_url} />
                </Helmet> */}

                {(optionsAcf !== false) &&
                    <div className="archive flex flex-col">
                        <div className="archive-header container mx-auto">
                            <div className="intro flex flex-row justify-between">
                                <h1>{optionsAcf.acf.title}</h1>
                                <div className="intro-text">
                                    <p>{optionsAcf.acf.intro_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            {stories && <div className="container mx-auto">
                <FilterCheckbox filterState={{ setFilter, filter }} filterItems={['Alles', ...allCategoryNames]} radio={true} />
            </div>}
            <div className="stories container mx-auto flex flex-col">
                {filteredDataState && filteredDataState.map((single, index) =>
                    <div className={'stories-single'} key={index}>
                        <Link
                            to={'/' + single.type + '/' + single.slug}
                            className={isEven(index) && 'flex flex-row justify-between items-center flex-row-reverse mirrored' || 'flex flex-row justify-between items-center'}>
                            <div className="content">
                                <h2>{single.title.rendered}
                                    <div className="active-border"></div>
                                </h2>
                                <div dangerouslySetInnerHTML={{ __html: single.content.rendered.slice(0, 175).concat('…') }}></div>
                                <span>Lees meer</span>
                            </div>
                            <img src={single.fimg_url} alt="" />
                        </Link>
                    </div>
                )}
            </div>

        </Transitions>
    )
}

export default Stories