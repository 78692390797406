import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FetchApiData, getTagNamesAndIds } from '../functions/helpers';
//import { Helmet } from "react-helmet";

import SectionHeroHome from '../partials/SectionHeroHome';
import SectionHeroAbout from '../partials/SectionHeroAbout';
import SectionSpacer from '../partials/SectionSpacer';
import SectionDuoNav from '../partials/SectionDuoNav';
import SectionTextSlider from '../partials/SectionTextSlider';
import SectionHeroBannerText from '../partials/SectionHeroBannerText';
import SectionHeroProduct from '../partials/SectionHeroProduct';
import SectionInfoProduct from '../partials/SectionInfoProduct';
import SectionVideoBanner from '../partials/SectionVideoBanner';

import Transitions from '../partials/animations/Transitions'
import FadeUp from '../partials/animations/FadeUp'

import '../assets/scss/haarden-assortiment.scss';
import FilterCheckbox from '../partials/components/FilterCheckbox';

import {gsap} from 'gsap';
import { useEffect } from 'react';

const Assortimenten = ({ data }) => {
    let currentSlug = data;
    currentSlug = currentSlug.replace(/\//g, "");
    const [filter, setFilter] = useState(['Alles'])
    const [filteredDataState, setFilteredDataState] = useState(null)

    let page = FetchApiData('wp-json/wp/v2/' + currentSlug + '?per_page=100');
    let tags = FetchApiData('/wp-json/wp/v2/tags');
    const optionsAcf = FetchApiData('wp-json/acf/v3/options/assortimenten');



    // tagdata
    const allTagsAndNames = getTagNamesAndIds(page, tags)
    const allTagNames = allTagsAndNames.map(i => i.name);

    const filteredIDs = allTagsAndNames.filter(i => filter.includes(i.name)).map(i => i.id);


    const filteredData = () => {
        let prevData = document.querySelectorAll('.assortiment-single');


        if (page) {
            if (filter.includes('Alles')) {
                animateData(prevData, page)
                return
            }
            let filteredData = []
            page.map(post => {
                post.tags.map(tag => {
                    if (filteredIDs.includes(tag) && !filteredData.includes(post)) {
                        filteredData.push(post)
                    }
                })
            })
            animateData(prevData, filteredData)
            return

        } else {
            setFilteredDataState(null)
            return
        }
    }

    const animateData = (prevData, newData) => {

        gsap.to(prevData, {
            duration: 0.5,
            opacity: 0,
            y: -10,
            ease: 'power3.inOut',
            stagger: 0.1,
            onComplete: () => {
                setFilteredDataState(newData)
                return
            }
        })
    }

    useEffect(() => {
        filteredData()
    }, [filter, page])

    useEffect(() => {
        let newDataElements = document.querySelectorAll('.assortiment-single');

        gsap.fromTo(newDataElements, 
            {
                y: -10,
                opacity: 0
            },{
                duration: 0.5,
                opacity: 1,
                y: 0,
                ease: 'power3.inOut',
                stagger: 0.1,
            })
    }, [filteredDataState])
    
    
    
    return (

        <Transitions>
            <div className='page-container'>
                {/* <Helmet>
                    <title>{page[0].yoast_head_json.title}</title>
                    <meta property='og:locale' content='nl_NL' />
                    <meta name="description" content={page[0].yoast_head_json.description} />
                    <meta property='og:title' content={page[0].yoast_head_json.og_title} />
                    <meta property='og:description' content={page[0].yoast_head_json.og_description} />
                    <meta property='og:url' content={page[0].yoast_head_json.og_url} />
                </Helmet> */}

                {(optionsAcf !== false) &&
                    <div className="archive flex flex-col">
                        <div className="archive-header container mx-auto">
                            <div className="intro flex flex-row justify-between">
                                <h1>{optionsAcf.acf.title}</h1>
                                <div className="intro-text">
                                    <p>{optionsAcf.acf.intro_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {page && tags && <div className="container mx-auto">
                    <FilterCheckbox filterState={{ setFilter, filter }} filterItems={['Alles', ...allTagNames]} radio={true} />
                </div>}

                <div className="product-grid container-big mx-auto flex flex-row flex-wrap justify-between">

                    {filteredDataState && filteredDataState.map((single, index) =>
                        <div className={'assortiment-single'} key={index} i={index}>
                            <Link to={'/' + single.type + '/' + single.slug} key={index}>
                                <img src={single.fimg_url} alt="" />
                                <div className="content--hover">
                                    <h3>{single.title.rendered}</h3>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>

                {(optionsAcf !== false) &&
                    <>
                        {(optionsAcf.acf.flex_header !== false || optionsAcf.acf.flex_header.length > 0) && optionsAcf.acf.flex_header.map((hero, index) =>
                            <div className='hero-container' key={index}>
                                {hero.acf_fc_layout == 'section__hero-home' && <SectionHeroHome data={hero} />}
                                {hero.acf_fc_layout == 'section__hero-about' && <SectionHeroAbout data={hero} />}
                                {hero.acf_fc_layout == 'section__hero_product' && <SectionHeroProduct data={hero} />}
                                {hero.acf_fc_layout == 'spacer' && <SectionSpacer data={hero} />}
                            </div>
                        )}

                        {(optionsAcf.acf.flex_content.length > 0) && optionsAcf.acf.flex_content.map((layout, index) =>
                            <div className='content-container' key={index}>
                                {layout.acf_fc_layout == 'section__duo-nav' && <SectionDuoNav data={layout} />}
                                {layout.acf_fc_layout == 'spacer' && <SectionSpacer data={layout} />}
                                {layout.acf_fc_layout == 'section__text_slider' && <SectionTextSlider data={layout} />}
                                {layout.acf_fc_layout == 'section__hero_banner_text' && <SectionHeroBannerText data={layout} />}
                                {layout.acf_fc_layout == 'section__info_product' && <SectionInfoProduct data={layout} />}
                                {layout.acf_fc_layout == 'section__video_banner' && <SectionVideoBanner data={layout} />}
                            </div>
                        )}
                    </>
                }
            </div>
        </Transitions>
    );
}

export default Assortimenten