import { useEffect, useState } from 'react';

// Fetch Data from API by given URL
// Returns error, loading.. if succes items{}
function FetchApiData(jsonSlug) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const url = process.env.REACT_APP_REST_URL + jsonSlug;

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [url])

    if (error) {
        return error
    } else if (!isLoaded) {
        return false
    } else {
        return items;
    }
}

export {
    FetchApiData
};

export const getTagNamesAndIds = (posts, tags) => {
    let tagNamesAndIDs = [];
    posts && tags && posts.map(post => {
        post.tags.map(tagID => {
            tags.find(t => {
                if (t.id === tagID && !tagNamesAndIDs.some(e => e.id === t.id)) {
                    tagNamesAndIDs.push({
                        name: t.name,
                        id: t.id
                    })
                }
            })
        })
    })
    return tagNamesAndIDs;
}

export const getPostTagNamesAndIds = (post, tags) => {
    let tagNamesAndIDs = [];
    post.tags.map(tagID => {
        tags.find(t => {
            if (t.id === tagID && !tagNamesAndIDs.some(e => e.id === t.id)) {
                tagNamesAndIDs.push({
                    name: t.name,
                    id: t.id
                })
            }
        })
    })
    return tagNamesAndIDs;
}

export const getCategoryNamesAndIds = (posts, categories) => {
    let categoryNamesAndIds = [];
    posts && categories && posts.map(post => {
        post.categories.map(tagID => {
            categories.find(t => {
                if (t.id === tagID && !categoryNamesAndIds.some(e => e.id === t.id)) {
                    categoryNamesAndIds.push({
                        name: t.name,
                        id: t.id
                    })
                }
            })
        })
    })
    return categoryNamesAndIds;
}