import React from 'react';
import { Link } from 'react-router-dom'
import '../assets/scss/section__duo-nav.scss';

const SectionDuoNav = ({ data }) => {

    const dataAcf = data['section__duo-nav'];

    return (


        <section className='duo-nav'>
            <div className="wrap container mx-auto flex justify-between items-start">
                {dataAcf?.col_left &&
                    <div className="col-50 flex flex-col justify-start items-start">
                        <h3>{dataAcf.col_left.title}</h3>

                        {dataAcf.col_left?.description &&
                            <p> {dataAcf.col_left.description} </p>
                        }

                        {dataAcf.col_left?.nav &&
                            <nav className="nav flex flex-col">
                                {dataAcf.col_left.nav.map((linkItem, index) =>
                                    <Link to={linkItem.link.url} key={index}>{linkItem.link.title}</Link>
                                )}
                            </nav>
                        }
                    </div>
                }

                {dataAcf?.col_right &&
                    <div className="col-50 col-right flex flex-col justify-start items-end">
                        <h3>{dataAcf.col_right.title}</h3>

                        {dataAcf.col_right?.description &&
                            <p> {dataAcf.col_right.description} </p>
                        }

                        {dataAcf.col_right?.nav &&
                            <nav className="nav flex flex-col">
                                {dataAcf.col_right?.nav.map((linkItem, index) =>
                                    <a href={linkItem.link.url} key={index}>{linkItem.link.title}</a>
                                )}
                            </nav>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default SectionDuoNav