import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import svgDownload from '../assets/images/download.svg';
import menuClose from '../assets/images/exit-cross.svg';
import svgArrow from '../assets/images/triangle.svg';
import '../assets/scss/FilterCheckbox.scss';
import '../assets/scss/section__downloads.scss';
import { FetchApiData } from '../functions/helpers';
gsap.registerPlugin(ScrollToPlugin);

// const handlePopup = (item) => {
//     // console.log(item)
//     const div = document.createElement('div')
//     div.classList.add('popup-model')
//     div.id = 'popup-model'

//     if (item.optional == 'pdf') {
//         // niks hier want je download de pdf
//         return
//     }
//     if (item.optional == 'video') {
//         div.innerHTML = "<div><h3>" + item.title + "</h3><video controls><source src=" + item.video.url + " type='video/mp4'></video><div id='exit-popup'><img src=" + menuClose + " ></div ></div > ";
//     }
//     if (item.optional == 'text') {
//         var title = item.title;
//         var onderwerp = item.onderwerp;
//         var haard = item.haard;
//         var text = item.text;
//         div.innerHTML =
//             "<div><h3>" + title + "</h3><h4>" + onderwerp + "</h4><h4>" + haard + "</h4><span>" + text + "</span><div id='exit-popup' ><img src=" + menuClose + " ></div></div>";
//     }

//     //apend correcte kind
//     console.log(div)
//     document.body.appendChild(div);
//     document.getElementById('exit-popup').addEventListener('click', function () {
//         document.body.removeChild(document.getElementById('popup-model'));
//     });
//     return
// }

//main component
const SectionDownloads = ({ data, page }) => {
	const [filterShown, setFilterShown] = useState(false);
	const [selectedOnderwerp, setSelectedOnderwerp] = useState(null)
	const gridHook = useRef(null);

	// console.log(page)
	let subPages = FetchApiData(
		'wp-json/kennisbank-options/children?ID=' + page[0].id
	);
	var dataObjects = subPages;
	const [filter, setFilter] = useState({
		optional: [],
		onderwerp: [],
		haard: [],
	});


	useEffect(() => {
		// get url parameter "onderwerp"
		const urlParams = new URLSearchParams(window.location.search);
		const onderwerp = urlParams.get('onderwerp');


		if(onderwerp) {
			setSelectedOnderwerp(onderwerp)
			// add onderwerp capitalize first letter to filter
			setFilter({
				...filter,
				onderwerp: [onderwerp]
			})
		}
	}, [])

	function handleFilter(item) {
		let currentFilterValues = filter;

		for (const key in currentFilterValues) {
			if (key == item.target.id.split('-')[0]) {
				if (filter[key].includes(item.target.name)) {
					setFilter({
						...filter,
						[key]: filter[key].filter(
							(filterItem) => filterItem !== item.target.name
						),
					});
				} else {
					setFilter({
						...filter,
						[key]: [...filter[key], item.target.name],
					});
				}
			}
		}
	}

	const filteredItems = () => {
		let objects = [];
		dataObjects &&
			dataObjects.map((item) => {
				for (const key in filter) {
					if (filter[key].length > 0) {
						if (!filter[key].includes(item.acf[key])) {
							return;
						}
					}
				}
				objects.push(item);
			});

		if (objects.length || isFilterSet()) {
			return objects;
		} else {
			return dataObjects;
		}
	};

	function isFilterSet() {
		for (const key in filter) {
			if (filter[key].length > 0) {
				return true;
			}
		}
		return false;
	}

	return (
		<section className="downloads grid grid-cols-12 mb-32" id="downloads">
			<button
				onClick={() => setFilterShown((prev) => !prev)}
				className={
					'col-span-12 bg-black py-2 text-center mb-12 text-white font-bold block lg:hidden'
				}
			>
				Filter
			</button>
			<div
				className={
					'downloads-header col-span-12 lg:col-span-3 lg:block ' +
					(filterShown ? '' : 'hidden')
				}
			>
				{dataObjects && (
					<HeaderContent
						data={dataObjects}
						handleFilter={handleFilter}
						filter={filter}
					/>
				)}
				<div ref={gridHook} />
			</div>
			<div className="downloads-content col-span-12 lg:col-span-9">
				{/* display grid */}
				<DisplayDownloads items={filteredItems()} page={page} />
			</div>
		</section>
	);
};

const DisplayDownloads = ({ items, page }) => {
	return (
		<div className="downloads-grid-content-grid  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
			{items &&
				items.map((item, index) => (
					<Link
						to={`/kennisbank/${page[0].slug}/${item.post_name}`}
						key={index}
						className="grid-item"
					>
						<img src={item?.thumbnail} />
						<div className="grid-item-content">
							<div className="content-main">
								<h3>{item?.post_title}</h3>
								<p>{item?.acf?.onderwerp}</p>
							</div>
						</div>
					</Link>
				))}
		</div>
	);
};

//load in onderwerpen && haarden && type
const HeaderContent = ({ data, handleFilter, filter }) => {
	const filterItemsSortOrder = [
		'Alles',
		'New Forest Insert',
		'New Forest Square',
		'New Forest 650 insert',
		'New Forest 870',
		'New Forest 1200',
		'New Forest 1200 tall',
		'New Forest 1600',
		'New Forest 1900',
		'New Forest 2400',
		'Brockenhurst',
		'Winchester',
		'Holbury',
		'Ashurst',
		'Bramshaw',
		'Hinton',
		'Hinton vrijstaande haard',
	];

	const newOnderwerpen = [...data, ...data]
		.map((item) => item.acf.onderwerp)
		.filter((item, index, array) => array.indexOf(item) === index);
	let haarden = [...data, ...data]
		.map((item) => item.acf.haard)
		.filter((item, index, array) => array.indexOf(item) === index);
	const type = [...data, ...data]
		.map((item) => item.acf.optional)
		.filter((item, index, array) => array.indexOf(item) === index);

	// filter haarden on sort order
	haarden = haarden.sort((a, b) => {
		return (
			filterItemsSortOrder.indexOf(a) -
			filterItemsSortOrder.indexOf(b)
		);
	});

	// newOnderwerpen = newOnderwerpen.filter(filterItem => filterItem !== )
	return (
		<div className="downloads-header-content">
			<div className="radio-container">
				<h3>Type</h3>
				{type &&
					type.map((item, index) => (
						<div key={index}>
							<span className="checkbox">
								<input
									type="checkbox"
									name={item}
									id={'optional-' + item}
									onChange={(e) => handleFilter(e)}
								/>
								<label htmlFor={'optional-' + item}>
									{/* capitalize first letter */}
									{item.charAt(0).toUpperCase() + item.slice(1)}
								</label>
							</span>
						</div>
					))}
			</div>
			<div className="radio-container">
				<h3>Onderwerpen</h3>
				{newOnderwerpen &&
					newOnderwerpen.map((item, index) => {
						const isActive = filter.onderwerp.includes(item)
						return (
							<div key={index}>
								<span className="checkbox">
									<input
										type="checkbox"
										name={item}
										id={'onderwerp-' + item}
										onChange={(e) => handleFilter(e)}
										checked={isActive}
									/>
									<label htmlFor={'onderwerp-' + item}>
										{item}
									</label>
								</span>
							</div>
						);
					})}
			</div>
			<div className="radio-container">
				<h3>Haarden</h3>
				{haarden &&
					haarden.map((item, index) => {
						if(item == '') return
						return (
						<div key={index}>
							<span className="checkbox">
								<input
									type="checkbox"
									name={item}
									id={'haard-' + item}
									onChange={(e) => handleFilter(e)}
								/>
								<label htmlFor={'haard-' + item}>{item}</label>
							</span>
						</div>
					)})}
			</div>
		</div>
	);
};

export default SectionDownloads;
