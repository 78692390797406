/* eslint-disable */
import React, {useState} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import '../../assets/scss/maps.scss';

const GoogleMapMap = ({data}) => {
    const [marker, setMarker] = useState(null);

    // console.log(data)

    const containerStyle = {
        width: '100%',
        height: '630px'
    };

    const center = {
        lat: 52.1565799,
        lng: 5.0962667
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBWQcY_e0b07R1MCZIbEm3PCeBJjqJ7D2s"
    })

    const infoPopStyle = {
        background: `white`,
        border: `1px solid #ccc`,
        padding: 15,
    }


 if (isLoaded) {
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={7.5}
        >
        { Object.keys(data).map((key, index ) => {
            let lat = parseFloat(data[key].lat);
            let lon = parseFloat(data[key].lon);

            return (
                <div className='location-item' key={index}>
                    { marker === index && (
                        <InfoWindow style={infoPopStyle}
                        position={{lat: lat, lng: lon }}
                        onCloseClick={() => setMarker(null)}
                        >
                        <div className='info-pop flex flex-col'>
                            <h3>{ data[key].company_name }</h3>
                            <a className='web-url' href={ data[key].website } target="_blank">{ data[key].website }</a>
                            <span><b>E-mail:</b> <a href={ 'mailto:' + data[key].email }>{ data[key].email }</a></span>
                            <span><b>Telefoon:</b> <a href={ 'tel:' + data[key].phone }>{ data[key].phone }</a></span>
                        </div>
                        </InfoWindow>
                     )}

                    <Marker
                     position={{lat: lat, lng: lon }}
                     onClick={() => setMarker(index)}
                     />
                </div>
            )
          })
        }


        </GoogleMap>
      ) 
 }
} 

export default GoogleMapMap