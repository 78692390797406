import React, { useState } from 'react';

const InputField = ({ name, error, value, placeholder, onChangeHandler, textarea, type, ...props }) => {

  if(!type) {
    type = 'text';
  }

  if(textarea) {
    return (
      <div className="flex flex-col gap-1 col-span-2">
          <textarea
          {...props}
          type={type}
          name={name}
          value={value}
          className="border-b-2 h-[150px] border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2"
          placeholder={placeholder}
          onChange={(e) => onChangeHandler(e)}
          />
          {error && <div className="text-red-500 text-sm">{error}</div>}
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-1">
        <input
        {...props}
        type={type}
        name={name}
        value={value}
        className="border-b-2 border-t-0 border-x-0 focus:ring-0 focus:outline-0 focus:border-b-2 focus:border-gray-500 border-gray-500 bg-transparent py-2"
        placeholder={placeholder}
        onChange={(e) => onChangeHandler(e)}
        />
        {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
}

export default InputField;
