import React from 'react';
import { Helmet } from 'react-helmet';
import { FetchApiData } from '../functions/helpers';

import readingIcon from '../assets/images/reading-time.svg';
import '../assets/scss/story.scss';

import facebook from '../assets/images/facebook.svg';
import linkedin from '../assets/images/linkedin.svg';
import twitter from '../assets/images/twitter.svg';
import whatsapp from '../assets/images/whatsapp.svg';
import Transitions from '../partials/animations/Transitions';

const KennisbankSingleChild = ({ data }) => {
	let currentSlug = data.split('/').pop();
	let page = FetchApiData('wp-json/wp/v2/kennisbank?slug=' + currentSlug);

	let postDate = page[0]?.date;

	if (postDate) {
		postDate = postDate.split('T')[0];
	}

	return (
		<Transitions>
			<div className="page-container">
				<Helmet>
					<title>{page[0]?.yoast_head_json.title}</title>
					<meta property="og:locale" content="nl_NL" />
					<meta
						name="description"
						content={page[0]?.yoast_head_json.description}
					/>
					<meta
						property="og:title"
						content={page[0]?.yoast_head_json.og_title}
					/>
					<meta
						property="og:description"
						content={page[0]?.yoast_head_json.og_description}
					/>
					<meta
						property="og:url"
						content={page[0]?.yoast_head_json.og_url}
					/>
				</Helmet>

				<div className="container-small mx-auto single-story">
					<div className="header">
						{/* <img src={page[0]?.fimg_url} alt="" className="featured-img" /> */}
						<div className="content">
							<h1>{page[0]?.title.rendered}</h1>
						</div>
						<div className="meta content mb-6 flex flex-row justify-between items-center">
							<div className="post-data flex flex-row justify-start items-center">
								<div className="date">{postDate}</div>
								{/* <span>|</span>
								<div className="reading-time flex flex-row items-center">
									<img src={readingIcon} alt="" />
									<span>
										{
											page[0]?.yoast_head_json
												.twitter_misc[
												'Est. reading time'
											]
										}
									</span>
								</div> */}
							</div>
							<div className="social-share flex flex-row items-center gap-4">
								<span>Delen:</span>
								<a
									href={
										'https://www.facebook.com/sharer/sharer.php?u=' +
										window.location.href
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={facebook} alt="" />
								</a>
								<a
									href={
										'https://www.linkedin.com/sharing/share-offsite/?url=' +
										window.location.href
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={linkedin} alt="" />
								</a>
								<a
									data-action="share/whatsapp/share"
									href={
										'whatsapp://send?text=' +
										window.location.href
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={whatsapp} alt="" />
								</a>
								<a
									href={
										'https://twitter.com/intent/tweet?url=' +
										window.location.href
									}
									target="_blank"
									rel="noreferrer"
								>
									<img src={twitter} alt="" />
								</a>
							</div>
						</div>
					</div>
					<div
						className="content"
						dangerouslySetInnerHTML={{
							__html: page[0]?.content.rendered,
						}}
					></div>
				</div>
			</div>
		</Transitions>
	);
};

export default KennisbankSingleChild;
