import React from 'react'
import '../assets/scss/section_hero_banner_text.scss'

const SectionHeroBannerText = ({ data }) => {
    data = data.section__hero_banner_text;

    return (
        <div className='hero-banner-text container mx-auto'>
            <div className='hero-img'>
                <img src={data.hero_banner_text_image.url} alt={data.hero_banner_text_image.alt} />
            </div>
            <div className="hero-content flex flex-row justify-between">
                <div className="col">
                    <h2>{data.hero_banner_text_title}</h2>
                </div>
                <div className="col" dangerouslySetInnerHTML={{ __html: data.hero_banner_text_content }}></div>
            </div>
        </div>
    )
}

export default SectionHeroBannerText