import React from 'react'
import { FetchApiData } from '../functions/helpers';
//import { Helmet } from "react-helmet";

import Transitions from '../partials/animations/Transitions'

import '../assets/scss/haarden-assortiment.scss';
import FlexibleLayout from '../partials/components/FlexibleLayout';

const Kennisbank = () => {
    // let currentSlug = data;
    // currentSlug = currentSlug.replace(/\//g, "");

    //let page = FetchApiData('wp-json/wp/v2/' + currentSlug);
    const optionsAcf = FetchApiData('wp-json/acf/v3/options/kennisbank');

    return (

        <Transitions>
            <div className='page-container'>
                {/* <Helmet>
                    <title>{page[0].yoast_head_json.title}</title>
                    <meta property='og:locale' content='nl_NL' />
                    <meta name="description" content={page[0].yoast_head_json.description} />
                    <meta property='og:title' content={page[0].yoast_head_json.og_title} />
                    <meta property='og:description' content={page[0].yoast_head_json.og_description} />
                    <meta property='og:url' content={page[0].yoast_head_json.og_url} />
                </Helmet> */}

                {optionsAcf &&
                    <FlexibleLayout optionsAcf={optionsAcf} />
                }
            </div>
        </Transitions>
    );
}

export default Kennisbank