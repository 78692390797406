import React, {
    useState,
    useEffect
} from 'react';


const ImageContainer = ({ images }) => {
    const [pairs, setpairs] = useState(null);

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const createValuePairs = (images) => {
        let pairs = [];
        for (let i = 0; i < images.length / 2; i++) {
            let random = randomIntFromInterval(4, 8)
            pairs.push(random)
            pairs.push(12 - random)
        }
        return pairs
    }

    useEffect(() => {
        setpairs(createValuePairs(images))
    }, []);


    return (
        //html
        <section className='inspiratie-grid__inner'>
            {images && pairs && images.map((item, index) => {
                let finalWidth = pairs[index]
                if (index + 1 === images.length && images.length % 2 !== 0) {
                    finalWidth = 12
                }
                return (
                    <img width={'100%'} className={'col-span-' + finalWidth} key={index} src={item.url} />
                )
            })}
        </section>
    );
}

export default ImageContainer