import React from 'react';
import '../assets/scss/section__hero-about.scss';

const SectionHeroAbout = ({ data }) => {
	const dataAcf = data['section__hero-about'];

	return (
		<section className="hero-about container mx-auto flex flex-wrap lg:flex-nowrap flex-row justify-between items-center">
			<div className="col-intro flex flex-col">
				<h1
					className="title"
					dangerouslySetInnerHTML={{ __html: dataAcf.content.title }}
				/>
				<p className="intro">{dataAcf.content.text}</p>
			</div>
			<div className="hero-img md:relative">
				<img
					src={dataAcf.hero_image.url}
					alt={dataAcf.hero_image.alt}
				/>
			</div>

			<div
				className="scrolldown flex flex-row justify-center items-center"
				id="scroll-down"
			>
				<span>SCROLL</span>
				<div className="border-fff"></div>
			</div>
		</section>
	);
};

export default SectionHeroAbout;
