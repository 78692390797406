import React from 'react';
import { Helmet } from 'react-helmet';
import { FetchApiData } from '../functions/helpers';

import Transitions from '../partials/animations/Transitions';
import SectionDownloads from '../partials/SectionDownloads';
import SectionDuoNav from '../partials/SectionDuoNav';
import SectionFaqItems from '../partials/SectionFaqItems';
import SectionHeroAbout from '../partials/SectionHeroAbout';
import SectionHeroBannerText from '../partials/SectionHeroBannerText';
import SectionHeroHome from '../partials/SectionHeroHome';
import SectionHeroProduct from '../partials/SectionHeroProduct';
import SectionInfoProduct from '../partials/SectionInfoProduct';
import SectionSpacer from '../partials/SectionSpacer';
import SectionStoriesSlider from '../partials/SectionStoriesSlider';
import SectionTextSlider from '../partials/SectionTextSlider';
import SectionVideoBanner from '../partials/SectionVideoBanner';
import SectionWysiwyg from '../partials/SectionWysiwyg';

import '../assets/scss/section__kennisbank-single.scss';

const KennisbankSingle = ({ data }) => {
	let currentSlug = data;
	currentSlug = currentSlug.replace(/\//g, '');
	currentSlug = currentSlug.replace('kennisbank', '');
	let page = FetchApiData('wp-json/wp/v2/kennisbank?slug=' + currentSlug);

	return (
		<Transitions>
			<div className="page-container">
				<Helmet>
					<title>{page[0]?.yoast_head_json.title}</title>
					<meta property="og:locale" content="nl_NL" />
					<meta
						name="description"
						content={page[0]?.yoast_head_json.description}
					/>
					<meta
						property="og:title"
						content={page[0]?.yoast_head_json.og_title}
					/>
					<meta
						property="og:description"
						content={page[0]?.yoast_head_json.og_description}
					/>
					<meta
						property="og:url"
						content={page[0]?.yoast_head_json.og_url}
					/>
				</Helmet>

				<div className="kennisbank-header gap-2 md:gap-0 flex flex-col md:flex-row md:items-center justify-start container mx-auto pt-16">
					<h1>{page[0]?.type}</h1>
					<div className="line "></div>
					<p>{page[0]?.slug.replaceAll('-', ' ')}</p>
				</div>

				{page[0]?.type == 'kennisbank' && (
					<>
						{page[0].acf.flex_header.length > 0 &&
							page[0].acf.flex_header.map((hero, index) => (
								<div className="hero-container" key={index}>
									{hero.acf_fc_layout ==
										'section__hero-home' && (
										<SectionHeroHome data={hero} />
									)}
									{hero.acf_fc_layout ==
										'section__hero-about' && (
										<SectionHeroAbout data={hero} />
									)}
									{hero.acf_fc_layout ==
										'section__hero_product' && (
										<SectionHeroProduct data={hero} />
									)}
									{hero.acf_fc_layout == 'spacer' && (
										<SectionSpacer data={hero} />
									)}
								</div>
							))}

						{page[0].acf.flex_content.length > 0 &&
							page[0].acf.flex_content.map((layout, index) => (
								<div
									className="content-container container mx-auto"
									key={index}
								>
									{layout.acf_fc_layout ==
										'section__duo-nav' && (
										<SectionDuoNav data={layout} />
									)}
									{layout.acf_fc_layout == 'spacer' && (
										<SectionSpacer data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__text_slider' && (
										<SectionTextSlider data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__hero_banner_text' && (
										<SectionHeroBannerText data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__info_product' && (
										<SectionInfoProduct data={layout} />
									)}
									{layout.acf_fc_layout == 'wysiwyg' && (
										<SectionWysiwyg data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__video_banner' && (
										<SectionVideoBanner data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__faq_items' && (
										<SectionFaqItems data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__stories_slider' && (
										<SectionStoriesSlider data={layout} />
									)}
									{layout.acf_fc_layout ==
										'section__downloads' && (
										<SectionDownloads
											data={layout}
											page={page}
										/>
									)}
								</div>
							))}
					</>
				)}
			</div>
		</Transitions>
	);
};

export default KennisbankSingle;
