import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import checkMark from '../assets/images/checker.svg';
import '../assets/scss/section__dealers.scss';
import FilterCheckbox from './components/FilterCheckbox';
import GoogleMapMap from './components/GoogleMapMap';

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/src/all';
import { useEffect, useRef } from 'react';
import { FetchApiData } from '../functions/helpers';

gsap.registerPlugin(ScrollToPlugin);

const SectionDealers = ({ data }) => {
	const [filter, setFilter] = useState(['Alles']);
	const [activeDealers, setActiveDealers] = useState([]);
	const gridHook = useRef(null);

	const [postcode, setPostcode] = useState("");

	// Get googlemap data
	let locations = FetchApiData('/wp-json/gam-addresses/v1/adresses/');

	const allProductNames = () => {
		let names = [];
		data.dealers.map((dealer) => {
			dealer.producten_aanwezig &&
				dealer.producten_aanwezig?.map((product) => {
					!names.includes(product.product_naam) &&
						names.push(product.product_naam);
				});
		});

		return names;
	};

	const extractPostcode = (str) => {
		// Matches patterns like 1111aa or 1111 aa
		const nlPattern = /\b\d{4}\s?[a-zA-Z]{2}\b/;
		// Matches patterns like 44444
		const usPattern = /\b\d{5}\b/;
	
		const nlMatch = str.match(nlPattern);
		const usMatch = str.match(usPattern);
	
		if (nlMatch) return nlMatch[0];
		if (usMatch) return usMatch[0];
	
		return null;
	};
	
	const filteredDealers = () => {
		const dealers = data.dealers;
		let filteredDealers = [];
	
		dealers.map((dealer) => {
			const dealerPostcode = extractPostcode(dealer.adresgegevens);
	
			if (postcode && !dealerPostcode) {
				return; // Skip this dealer if no valid postcode is found in adresgegevens
			}
	
			if (postcode && !dealerPostcode.includes(postcode)) {
				return; // Skip this dealer if extracted postcode doesn't match user's input
			}
	
			if (filter.includes('Alles')) {
				filteredDealers.push(dealer);
				return;
			}
	
			dealer.producten_aanwezig &&
				dealer.producten_aanwezig?.map((product) => {
					if (filter.includes(product.product_naam)) {
						filteredDealers.push(dealer);
					}
				});
		});
	
		return filteredDealers;
	};
	


	useEffect(() => {
		gsap.to(window, {
			duration: 0.5,
			ease: 'power2.out',
			scrollTo: gridHook.current.offsetTop - 100,
		});
	}, [filter]);

	const Dealers = ({ items }) => {
		return (
			<>
				{items &&
					items.map((item, index) => (
						<div
							className="dealer-list-item flex flex-col bg-white rounded-md"
							key={index}
						>
							<div className="content flex flex-row w-full">
								<div className="info flex flex-col relative w-full">
									<div
										className="head flex flex-row justify-between"
										onClick={() =>
											setActiveDealers((prev) => {
												if (prev.includes(index)) {
													return prev.filter(
														(item) => item !== index
													);
												} else {
													return [...prev, index];
												}
											})
										}
									>
										<div>
											{activeDealers.includes(index) ? (
												<a
													href={item.website_link}
													target="_blank"
													rel="noreferrer"
												>
													{item.bedrijf_naam}
												</a>
											) : (
												<h3 className="pr-4 hover:cursor-pointer">
													{item.bedrijf_naam}
												</h3>
											)}
										</div>
										<div className="absolute top-1/2 -translate-y-1/2 right-0">
											{activeDealers.includes(index) ? (
												<svg
													data-accordion-icon
													className="w-6 h-6 rotate-180"
													fill="currentColor"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
														clipRule="evenodd"
													></path>
												</svg>
											) : (
												<svg
													data-accordion-icon
													className="w-6 h-6 "
													fill="currentColor"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
														clipRule="evenodd"
													></path>
												</svg>
											)}
										</div>
									</div>
									{activeDealers.includes(index) && (
										<div className="flex body">
											<div className="dealer-logo">
												<img
													src={item.logo.url}
													alt={item.logo.alt}
												/>
											</div>
											<div
												className="adress"
												dangerouslySetInnerHTML={{
													__html: item.adresgegevens,
												}}
											></div>
										</div>
									)}
								</div>
							</div>
							{activeDealers.includes(index) && (
								<div className="foot flex flex-row">
									<div className="locatie">Op locatie:</div>
									<div className="products-row flex flex-row">
										{item.producten_aanwezig &&
											item.producten_aanwezig.map(
												(product, index) => (
													<span
														key={index}
														className="product flex flex-row items-center"
													>
														<img
															src={checkMark}
															alt=""
														/>
														<Link
															to={
																product
																	.product_url
																	.url
															}
														>
															{
																product.product_naam
															}
														</Link>
													</span>
												)
											)}
									</div>
								</div>
							)}
						</div>
					))}
			</>
		);
	};

	return (
		<>
			<div className="dealers container mx-auto">
				<div className="header">
					<h1>Dealers</h1>
				</div>
				<div className="dealers-list flex flex-row items-start pb-24 gap-14">
					{/* <div className='filters flex flex-col gap-6'>
						<aside>
							<h4 className='break-all'>Haarden</h4>
							<FilterCheckbox
								filterState={{ setFilter, filter }}
								filterItems={['Alles', ...allProductNames()]}
								radio={true}
							/>
						</aside>
					</div> */}
					<div className='filters flex flex-col gap-6'>
						<aside>
							<div className="postcode-filter flex flex-col items-start mb-4 gap-2">
								<h4>Postcode</h4>
								<input 
									type="text" 
									id="postcode" 
									value={postcode} 
									onChange={(e) => setPostcode(e.target.value)} 
									placeholder="Voer postcode in"
									className="border p-2 rounded-md focus:border-blue-500 transition duration-150 ease-in-out"
								/>
							</div>
							<h4 className='break-all'>Haarden</h4>
							<FilterCheckbox
								filterState={{ setFilter, filter }}
								filterItems={['Alles', ...allProductNames()]}
								radio={true}
							/>
						</aside>
					</div>



					<div className="list flex flex-col">
						<div
							className="maps-container container !px-0 mx-auto"
							id="mapsContainer"
						>
							<div className="wrap">
								<GoogleMapMap data={locations} />
							</div>
						</div>
						<div ref={gridHook} />
						{filter && <Dealers items={filteredDealers()} />}
					</div>
				</div>
			</div>
		</>
	);
};

export default SectionDealers;
