import React from 'react'
import SectionDuoNav from '../SectionDuoNav'
import SectionHeroAbout from '../SectionHeroAbout'
import SectionHeroBannerText from '../SectionHeroBannerText'
import SectionHeroHome from '../SectionHeroHome'
import SectionHeroProduct from '../SectionHeroProduct'
import SectionInfoProduct from '../SectionInfoProduct'
import SectionListImage from '../SectionListImage'
import SectionSpacer from '../SectionSpacer'
import SectionTextSlider from '../SectionTextSlider'
import SectionVideoBanner from '../SectionVideoBanner'

const FlexibleLayout = ({optionsAcf}) => {
    return (
        <>
            {(optionsAcf.acf.flex_header !== false || optionsAcf.acf.flex_header.length > 0) && optionsAcf.acf.flex_header.map((hero, index) =>
                <div className='hero-container' key={index}>
                    {hero.acf_fc_layout == 'section__hero-home' && <SectionHeroHome data={hero} />}
                    {hero.acf_fc_layout == 'section__hero-about' && <SectionHeroAbout data={hero} />}
                    {hero.acf_fc_layout == 'section__hero_product' && <SectionHeroProduct data={hero} />}
                    {hero.acf_fc_layout == 'spacer' && <SectionSpacer data={hero} />}
                </div>
            )}

            {(optionsAcf.acf.flex_content.length > 0) && optionsAcf.acf.flex_content.map((layout, index) =>
                <div className='content-container' key={index}>
                    {layout.acf_fc_layout == 'section__duo-nav' && <SectionDuoNav data={layout} />}
                    {layout.acf_fc_layout == 'spacer' && <SectionSpacer data={layout} />}
                    {layout.acf_fc_layout == 'section__text_slider' && <SectionTextSlider data={layout} />}
                    {layout.acf_fc_layout == 'section__hero_banner_text' && <SectionHeroBannerText data={layout} />}
                    {layout.acf_fc_layout == 'section__info_product' && <SectionInfoProduct data={layout} />}
                    {layout.acf_fc_layout == 'section__video_banner' && <SectionVideoBanner data={layout} />}
                    {layout.acf_fc_layout == 'section__list_image' && <SectionListImage data={layout.section__list_image} />}
                </div>
            )}
        </>
    )
}

export default FlexibleLayout