import React from 'react'

const SectionSpacer = ({ data }) => {

    const dataAcf = data.spacer.spacer_amount + 'px';

    return (
        <div className='spacer' style={{ height: dataAcf }} ></div >
    )
}

export default SectionSpacer