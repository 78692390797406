import React from 'react';
import '../assets/scss/section__ImageGrid.scss';
import ImageContainer from './components/ImageContainer';

const SectionImageGrid = ({ data }) => {
    // console.log(data)
    // console.log(data.section__inspiratie_grid.inspiratie_grid_gallery)
    const imageArray = data.section__inspiratie_grid.inspiratie_grid_gallery;

    return (
        <div className='inspiratie-grid container mx-auto'>
            <div className='inspiratie-grid-header'>
                <h3>Inspiratie</h3>
                <p>maak uw huis compleet met een elektrische haard van Easyhaarden</p>
            </div>
            <ImageContainer images={imageArray} />
        </div>
    )
}

export default SectionImageGrid

